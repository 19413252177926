import React, { useEffect, useState } from "react";
import AppBarComponent from "../../Components/AppBarComponent";
import DrawerComponent from "../../Components/DrawerComponent";
import {
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import { fetchStaff } from "../../ApiComponents/StaffApi";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CheckCircleOutlineOutlined,
  CancelOutlined,
} from "@mui/icons-material";
import Footer from "../../Components/Footer";
import axios from "axios";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Staff = () => {
  //!Appbar and Drawer
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  //!dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const commonMarginStyle = {
    marginLeft: open ? "240px" : "0",
    "@media (max-width: 600px)": {
      marginLeft: open ? "120px" : "0",
    },
    "@media (min-width: 601px) and (max-width: 960px)": {
      marginLeft: open ? "40px" : "0",
    },
    "@media (min-width: 961px) and (max-width: 1280px)": {
      marginLeft: open ? "60px" : "0",
    },
  };

  const [selectedRole, setSelectedRole] = useState("");
  const [roleError, setRoleError] = useState("");

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    // Clear the error message when user selects a role
    setRoleError("");
  };

  const validateRole = () => {
    if (!selectedRole) {
      setRoleError("Role is required"); // Set roleError state if role is not selected
      return false;
    } else {
      setRoleError(""); // Clear the role error if role is selected
      return true;
    }
  };

  //! Tokens and Headers
  const user = JSON.parse(sessionStorage.getItem("user"));

  const headers = {
    "Content-type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  const [formData, setFormData] = useState({
    userName: "",
    fullName: "",
    mobileNumber: "",
    email: "",
    role: "",
  });

  const [formErrors, setFormErrors] = useState({
    userName: "",
    fullName: "",
    mobileNumber: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error message when user starts typing again
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Example validation - you can replace this with your own validation rules
    if (!formData.userName.trim()) {
      newErrors.userName = "User Name is required";
      isValid = false;
    }

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile Number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobileNumber.trim())) {
      newErrors.mobileNumber = "Mobile Number is invalid";
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  //!post method of Staff data
  const handlePostSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const isValidForm = validateForm() && validateRole(); // Validate role

    if (isValidForm) {
      try {
        // Perform POST request to your API endpoint
        const response = await axios.post("YOUR_API_ENDPOINT", formData, {
          headers: headers,
        });

        // Check if the request was successful
        if (response.status === 200) {
          // Perform any necessary actions after successful form submission
          console.log("Form submitted successfully:", response.data);

          // Close the dialog after successful form submission
          handleCloseDialog();

          // Reset form fields
          setFormData({
            userName: "",
            fullName: "",
            mobileNumber: "",
            email: "",
            role: "",
          });

          // Reset form errors
          setFormErrors({
            userName: "",
            fullName: "",
            mobileNumber: "",
            email: "",
          });

          // Fetch updated data or perform any other necessary actions
          // fetchStaffData(); // Example: You might want to fetch updated staff data
        } else {
          // Handle unsuccessful response
          console.error("Error submitting form: Unexpected status code");
        }
      } catch (error) {
        // Handle request error
        console.error("Error submitting form:", error);
      }
    } else {
      // Form is not valid, display validation errors
      console.error("Form validation failed.");
      // You can optionally display error messages to the user
    }
  };

  //todo ==> GET Staff Data
  const [staff, setStaff] = useState([]);
  // Fetch data and update rows
  useEffect(() => {
    fetchStaffData();
  }, []);

  const fetchStaffData = async () => {
    try {
      const staffData = await fetchStaff(headers); // Assuming fetchStaff function is correct
      const mappedStaffData = staffData.data.content.map((staffItem) => ({
        ...staffItem,
        id: staffItem.userId, // Use staffId as the id
        status: staffItem.active, // Assuming status is returned as active property
      }));
      console.log(mappedStaffData);
      setStaff(mappedStaffData);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const toggleStatus = async (staffId) => {
    try {
      // Call an API to toggle the status
      // For example, you might have an endpoint like /staff/toggleStatus
      // which accepts staffId as a parameter and toggles the status
      // Update the status locally after successful API call
      const updatedStaff = staff.map((item) =>
        item.id === staffId ? { ...item, status: !item.status } : item
      );
      setStaff(updatedStaff);
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const columns = [
    { field: "userId", headerName: "userId", width: 100 },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
      valueGetter: (params) => params.row.fullName || "N/A", // Handle null values
    },
    { field: "userName", headerName: "User Name", width: 150 },
    {
      field: "roleName",
      headerName: "Role Name",
      width: 150,
      valueGetter: (params) =>
        params.row.roleDto && params.row.roleDto.roleName
          ? params.row.roleDto.roleName
          : "N/A",
    },
    {
      field: "createdBy.userName",
      headerName: "Created By",
      width: 150,
      valueGetter: (params) =>
        params.row.createdBy ? params.row.createdBy.userName : "N/A", // Handle null values
    },
    {
      field: "updatedBy.userName",
      headerName: "Updated By",
      width: 150,
      valueGetter: (params) =>
        params.row.updatedBy ? params.row.updatedBy.userName : "N/A", // Handle null values
    },
    {
      field: "insertedDate",
      headerName: "Inserted Date",
      width: 150,
      valueGetter: (params) => {
        const date = params.row.insertedDate
          ? new Date(params.row.insertedDate)
          : null;
        return date ? date.toLocaleDateString() : "N/A";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <>
          <Chip
            label={params.row.status ? "Active" : "Inactive"}
            color={params.row.status ? "success" : "error"}
          />
          {params.row.status ? (
            <IconButton
              color="success"
              onClick={() => toggleStatus(params.row.id)}
            >
              <CheckCircleOutlineOutlined />
            </IconButton>
          ) : (
            <IconButton
              color="error"
              onClick={() => toggleStatus(params.row.id)}
            >
              <CancelOutlined />
            </IconButton>
          )}
        </>
      ),
    },

    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <IconButton color="primary">
          <EditIcon />
        </IconButton>
      ),
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   width: 100,
    //   renderCell: (params) => (
    //     <IconButton color="secondary">
    //       <DeleteIcon />
    //     </IconButton>
    //   ),
    // },
  ];

  return (
    <>
      <div style={{ backgroundColor: "#EEEEEE" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CssBaseline />
          <AppBarComponent open={open} handleDrawerOpen={handleDrawerOpen} />
          <DrawerComponent open={open} handleDrawerClose={handleDrawerClose} />

          <Box
            component="section"
            sx={{
              flexGrow: 1,
              transition: "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              marginLeft: open ? "200px" : "0",
              "@media (max-width: 600px)": {
                marginLeft: open ? "120px" : "0",
              },
            }}
          >
            <div style={{ marginLeft: "70px", marginTop: "80px" }}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Dashboard"
                    icon={<HomeIcon fontSize="small" />}
                  />
                  <StyledBreadcrumb component="a" href="#" label="Users" />
                  <StyledBreadcrumb
                    label="Staff"
                    deleteIcon={<ExpandMoreIcon />}
                    onDelete={handleClick}
                  />
                </Breadcrumbs>
              </div>

              <br />
              <div>
                <Card
                  sx={{
                    minWidth: 275,
                    boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <CardContent>
                    <Stack direction="row">
                      <Button variant="contained" onClick={handleOpenDialog}>
                        <AddIcon />
                        Add staff
                      </Button>
                      <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        PaperProps={{
                          component: "form",
                        }}
                      >
                        <DialogTitle>Add Staff</DialogTitle>
                        <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="userName"
                            name="userName"
                            label="User Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.userName}
                            onChange={handleChange}
                            required
                            error={!!formErrors.userName}
                            helperText={formErrors.userName}
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            id="fullName"
                            name="fullName"
                            label="Full Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                            error={!!formErrors.fullName}
                            helperText={formErrors.fullName}
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            id="mobileNumber"
                            name="mobileNumber"
                            label="Mobile Number"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formData.mobileNumber}
                            onChange={handleChange}
                            required
                            error={!!formErrors.mobileNumber}
                            helperText={formErrors.mobileNumber}
                          />
                          <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                          />
                          <FormControl
                            variant="standard"
                            fullWidth
                            error={!!roleError} // Set error prop based on roleError state
                          >
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                              labelId="role-label"
                              id="role"
                              name="role"
                              value={selectedRole}
                              onChange={handleRoleChange}
                            >
                              <MenuItem value={"admin"}>Admin</MenuItem>
                              <MenuItem value={"staff"}>Super Admin</MenuItem>
                            </Select>
                            <br/><br/>
                            {roleError && ( // Display error message if roleError is truthy
                              <FormHelperText error>{roleError}</FormHelperText> // Add error prop here
                            )}
                          </FormControl>
                          
                        </DialogContent>
                        <br/><br/>
                        <DialogActions>
                          <Button
                            onClick={handlePostSubmit}
                            variant="contained"
                          >
                            Submit
                          </Button>
                          <Button
                            onClick={handleCloseDialog}
                            variant="contained"
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Stack>
                    <br />
                    <div>
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                          rows={staff}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          pageSizeOptions={[10,50,100]}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            transition: "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            ...commonMarginStyle,
          }}
        >
          <Footer />
        </Box>
      </div>
    </>
  );
};

export default Staff;
